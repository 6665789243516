/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from 'react';
import { ModalDefault } from "../components/ModalDefault";
import api from '../services/api';

const AuthContext = createContext({});

export const STORAGE_KEYS = {
    user: '@App:user',
    token: '@App:token',
    acessibility: '@App:accessibility',
    isLogged: '@App:isLogged'
}

export function AuthProvider({ children }) {

    const [stageCollection, setStageCollection] = useState(false)
 
    const [loading, setLoading] = useState(false)

    //CONTROLLERS ACESSIBILIDADE
    const [themeContrast, setThemeContrast] = useState(1);
    const [themeFontSize, setThemeFontSize] = useState(1);
    const [themeLetterSpacing, setThemeLetterSpacing] = useState(1);
    const [themeFontFamily, setThemeFontFamily] = useState(1);

    const [modalErrorMessage, setModalErrorMessage] = useState('')
    const [modalErrorMessageUserInactive, setModalErrorMessageUserInactive] = useState('')
    const [modalErrorBlockedUserMessage, setModalErrorBlockedUserMessage] = useState('')
    useEffect(() => {
        if(loading) {
            const newAccessibilityObject = {
                themeContrast,
                themeFontSize,
                themeLetterSpacing,
                themeFontFamily
            }
            localStorage.setItem(STORAGE_KEYS.acessibility, JSON.stringify(newAccessibilityObject))
        }     
        // console.log("🐌🧲 ~ file: auth.jsx ~ line 34 ~ useEffect ~ newAccessibilityObject", newAccessibilityObject)
    }, [
        themeContrast,
        themeFontSize,
        themeLetterSpacing,
        themeFontFamily,        
    ])

    const [selectedBook, setSelectedBook] = useState({})

    const [focusTop, setFocusTop] = useState(false);
    const [focusBotton, setFocusBotton] = useState(false);

    const [injectSearch, setInjectSearch] = useState('')
    const [user, setUser] = useState({});
    const [isLogged, setIsLogged] = useState(false)
    // const isLoggedLocal = localStorage.getItem(STORAGE_KEYS.isLogged) === "true";
    const token = localStorage.getItem('@App:token');
    const [modalError, setModalError] = useState(false);
    const [modalErrorBlockedUser, setModalErrorBlockedUser] = useState(false);
    const [modalErrorUserInactive, setModalErrorUserInactive] = useState(false);
    // const [who, setWho] = useState('');
    const [modalErrorLoginType, setModalErrorLoginType] = useState(false)

    const [selectedNews, setSelectedNews] = useState({})

    const handleLoadStoredData = () => {
        // const userString = localStorage.getItem
        try {
            const accessibility = localStorage.getItem(STORAGE_KEYS.acessibility)
            // console.log("🐌🧲 ~ file: auth.jsx ~ line 80 ~ handleLoadStoredData ~ accessibility", accessibility)

            if (accessibility) {
                const parsedAccessibility = JSON.parse(accessibility)
                // console.log("🐌🧲 ~ file: auth.jsx ~ line 69 ~ handleLoadStoredData ~ parsedAccessibility", parsedAccessibility)
                setThemeContrast(parsedAccessibility.themeContrast)
                setThemeFontSize(parsedAccessibility.themeFontSize)
                setThemeLetterSpacing(parsedAccessibility.themeLetterSpacing)
                setThemeFontFamily(parsedAccessibility.themeFontFamily)
            }

            setLoading(true)
            const userString = localStorage.getItem(STORAGE_KEYS.user)

            const jwt = localStorage.getItem(STORAGE_KEYS.token)
            // console.log("🐌🧲 ~ file: auth.jsx ~ line 41 ~ handleLoadStoredData ~ jwt", jwt)

            if (!userString || !jwt) {
                return
            }

            const user = JSON.parse(userString)
            api.defaults.headers.Authorization = `Bearer ${jwt}`

            setUser(user)
            setIsLogged(true)

            localStorage.setItem(STORAGE_KEYS.isLogged, "true")
        } catch (error) {
            console.log(error, 'error during JSON parse')
        }
    }

    useEffect(() => {
        if (!loading) {
            handleLoadStoredData()
        }
    }, [loading])

    //ATUALIZAR DADOS DO USER
    async function RefreshUser() {
        await api.get('/user/custom/me').then(response => localStorage.setItem('@App:user', JSON.stringify(response.data))).then(resp => document.location.reload(true))
    }
    //LOGIN
    async function LoginCustomer(username, pass, callback) {
        try {
            const response = await api.post('/auth/local', {
                "identifier": username,
                "password": pass,
            })
            if (response) {
                if (response.status === 200) {
                    const { user, jwt } = response.data
                    if(user.inactive) {
                        setModalErrorUserInactive(true)
                        setModalErrorMessageUserInactive("Usuário inativo!")
                        return;
                    }
                    localStorage.setItem('@App:user', JSON.stringify(user));
                    localStorage.setItem('@App:token', jwt);
                    api.defaults.headers.Authorization = `Bearer ${jwt}`
                    setUser(user)
                    setIsLogged(true)
                    localStorage.setItem(STORAGE_KEYS.isLogged, "true")
                    callback()
                    return
                }
                setModalErrorLoginType(true)
                setModalError(true)
                setModalErrorMessage("Credenciais inválidas")
                throw new Error('Invalid credentials ' + JSON.stringify(response))
            }
            throw new Error('Unexpected response')
        } catch (error) {
            if (JSON.stringify(error).search('400') > 0) {
                if(error.response.data.message[0].messages[0].id === "Auth.form.error.blocked") {
                    setModalErrorBlockedUser(true)
                    setModalErrorBlockedUserMessage("Seu cadastro ainda está em validação. Aguarde até a liberação para realizar o login.")    
                } else {
                    setModalError(true)
                    setModalErrorMessage("Credenciais inválidas")
                }
            }
            console.log(error, 'error during login')
        }
    }
    //LOGOUT
    function Logout() {
        setUser({});
        setIsLogged(false)
        localStorage.clear();
        api.defaults.headers.Authorization = ''
        window.location.href = '/';
    }

    const handleUserUpdate = async (userForm, callback) => {
        try {
            const response = await api.put(`/user/update`, userForm)
            if (response) {
                if (response.status === 200) {
                    setUser(response.data)
                    localStorage.setItem(STORAGE_KEYS.user, JSON.stringify(response.data))
                    if (callback) callback()
                    return true
                }
                throw new Error('Unexpected response')
            }
            throw new Error('Network error ')
        } catch (error) {
            console.log(error, 'error during user update')
            setModalError(true)
            setModalErrorMessage(error.response.data.message)
        }
    }

    async function registerNewUser(accessCredentials, userForm, fileReport, fileForeigner, foreignerProofOfddress, successCallback) {
        userForm.confirmed = false;
        accessCredentials.provider = "local"
        let userid = null;
        try {
            const formData = new FormData()
            formData.append('files', fileReport)
            const responseUpload = await api.post('/upload/', formData)
            let responseUploadForeigner = null
            let responseUploadForeignerProofOfAddress = null
            if(userForm.foreigner) {
                const formDataForeigner = new FormData()
                formDataForeigner.append('files', fileForeigner)
                responseUploadForeigner = await api.post('/upload/', formData)
                const formDataForeignerProofOfAddress = new FormData()
                formDataForeignerProofOfAddress.append('files', foreignerProofOfddress)
                responseUploadForeignerProofOfAddress = await api.post('/upload/', formDataForeignerProofOfAddress)
            }
            if ((responseUpload !== undefined && responseUpload.status === 200) 
                || (user.foreigner && responseUploadForeigner !== undefined && responseUploadForeigner.status === 200
                    && responseUploadForeignerProofOfAddress !== undefined && responseUploadForeignerProofOfAddress.status === 200)
            ) {
                try {
                    const register = await api.post(`/user/register`, accessCredentials)
                    const finaleForm = {
                        ...userForm
                    }
                    if(register && register.status === 200){
                        const user = register.data.user
                        userid = user.id
                        api.defaults.headers.Authorization = `Bearer ${register.data.jwt}`
                        if (responseUpload !== undefined) {
                            if (responseUpload.status === 200) {
                                finaleForm.additionlInformation.visualConditionFile = {
                                    id: responseUpload.data[0].id
                                }
                                if(finaleForm.foreigner) {
                                    finaleForm.foreignerDocumentFile = {
                                        id: responseUploadForeigner.data[0].id
                                    }
                                    finaleForm.foreignerProofOfAddress = {
                                        id: responseUploadForeignerProofOfAddress.data[0].id
                                    }
                                }
                                let success = await handleUserUpdate(finaleForm)
                                delete api.defaults.headers.Authorization
                                if(success) {
                                    if (successCallback) successCallback()
                                } else {
                                    throw new Error('Error during user creation')
                                }
                                return
                            }
                        }
                    } else {
                        throw new Error('Error during user register')
                    }
                } catch (error) {
                    if(userid){
                        await api.delete(`/users/${userid}`).then(res => {
                            return res
                        }).catch(err => {
                            throw new Error('Error during deleting user' ,err)
                        })
                    }
                    if (JSON.stringify(error).search('400') > -1) {
                        setModalError(true)
                        setModalErrorMessage(error.response.data.message)
                    }
                    console.log(error, 'error during user creation')
                    return
                }
            } else {
                throw new Error('Error during upload')
            }
        } catch (error) {
            console.log(error)
            setModalError(true)
            setModalErrorMessage(`Ocorreu um erro durante o upload do laudo ${userForm.foreigner ? 'e/ou documento de estrangeiro' : ''}`)
        }
    }
    const handleRegisterEnterprise = async (userAccess, avatar, userForm, successCallback) => {
        try {
            const register = await api.post(`/auth/local/register`, userAccess)
            if (register) {
                if (register.status === 200) {
                    setUser(register.data.user)
                    setIsLogged(true)
                    localStorage.setItem(STORAGE_KEYS.token, register.data.jwt)
                    localStorage.setItem(STORAGE_KEYS.isLogged, "true")
                    localStorage.setItem(STORAGE_KEYS.user, JSON.stringify(register.data.user))
                    api.defaults.headers.Authorization = `Bearer ${register.data.jwt}`
                    // handleUserUpdate(userForm)
                    let finaleForm = {
                        ...userForm
                    }
                    try {
                        const formData = new FormData()
                        formData.append('files', avatar)
                        const response = await api.post('/upload', formData)
                        if (response !== undefined) {
                            if (response.status === 200) {
                                finaleForm.avatar = {
                                    id: response.data[0].id
                                }
                                handleUserUpdate(finaleForm)
                                if (successCallback) successCallback()
                                return
                            }
                            throw new Error('Error during user avatar update')
                        }
                        throw new Error('Error during upload')
                    } catch (error) {
                        console.log(error)
                        setModalError(true)
                        setModalErrorMessage("Ocorreu um erro durante o upload da imagem de avatar.")
                        handleUserUpdate(finaleForm)
                        if (successCallback) successCallback()
                    }
                    return
                }
            }
        } catch (error) {
            if (JSON.stringify(error).search('400') > -1) {
                setModalError(true)
                setModalErrorMessage("O e-mail já está em uso")
            }
            console.log(error, 'error during user creation')
        }
    }

    const closeModalShowLastRequiredField = () => {
        setModalErrorMessage("")
        setModalError(false)
    }

    const closeModalBlockedUser = () => {
        setModalErrorBlockedUserMessage("")
        setModalErrorBlockedUser(false)
    }

    const closeModalInactiveUser = () => {
        setModalErrorMessageUserInactive("")
        setModalErrorUserInactive(false)
    }
    // themeContrast, setThemeContrast, themeFontSize, setThemeFontSize, themeLetterSpacing, setThemeLetterSpacing, themeFontFamily, setThemeFontFamily

    return (
        <>
            {/* invalid fields */}
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError} overlay="overlay-login-error"
                onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            {/* blocked user */}
            <ModalDefault type="error" showCloseButton title="Atenção" isOpen={modalErrorBlockedUser} overlay="overlay-login-error"
                onRequestClose={() => closeModalBlockedUser()}>
                    <p>{modalErrorBlockedUserMessage}</p>
            </ModalDefault>
            {/* user inactive */}
            <ModalDefault type="error" showCloseButton title="Atenção!" isOpen={modalErrorUserInactive} overlay="overlay-login-error"
                onRequestClose={() => closeModalInactiveUser()}>
                    <p>{modalErrorMessageUserInactive}</p>
            </ModalDefault>
            <AuthContext.Provider value={{
                signed: true,
                user,
                RefreshUser,
                LoginCustomer,
                Logout,
                isLogged,
                token,
                themeContrast,
                setThemeContrast,
                themeFontSize,
                setThemeFontSize,
                themeLetterSpacing,
                setThemeLetterSpacing,
                themeFontFamily,
                setThemeFontFamily,
                selectedBook,
                setSelectedBook,
                handleUserUpdate,
                registerNewUser,
                setSelectedNews,
                selectedNews,
                setInjectSearch,
                injectSearch,
                handleRegisterEnterprise,
                setStageCollection,
                stageCollection,
                focusTop,
                setFocusTop,
                focusBotton,
                setFocusBotton
            }}>
                {children}
            </AuthContext.Provider>
        </>
    )
}


export default AuthContext;